import React, { useEffect } from 'react'
import { useEthers } from '@usedapp/core'

const TopNav = () => {
  useEffect(() => {
  }, [])
  

  const { activateBrowserWallet, deactivate, account } = useEthers()
  return (
    <div className="top_nav ">
      <div className="nav_menu">
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '14px'}}>
          {account ? (<p style={{margin: '0px'}}><b>Account:</b> {account}</p>): <p style={{margin: '0px'}}></p>}
          {
            account? (
              <button className="btn-connect" onClick={() => deactivate()}>Disconnet</button>
            ):(
              <button className="btn-connect" onClick={() => activateBrowserWallet()}>Connect</button>
            )
          }
        </div>
      </div>
    </div>

  )
}

export default TopNav