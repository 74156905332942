import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import TopNav from '../components/TopNav'
import Election from "../Election.json"
import getWeb3 from "../config/getWeb3"
import { PROJECT_ADDRESS } from '../config'

const Account = () => {
  const [accounts, setAccounts] = useState({});
  const [contract, setContract] = useState({});
  const [proposals, setProposals] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasVoted, setHasVoted] = useState(false)
  const [selectedProposal, setSelectedProposal] = useState("")
  const [owner, setOwner] = useState(PROJECT_ADDRESS)
  const [apiKey, setApiKey] = useState('ckey_9014ae23814045cdb2d7896827e')
  const [noNfts, setNoNfts] = useState([])
  const [nfts, setNfts] = useState([])
  
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const web3 = await getWeb3();
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();
      const network = Election.networks[networkId];
      const contract = new web3.eth.Contract(
        Election.abi,
        network && network.address
      );

      console.log('Election')
      console.log('Election',Election)

      setAccounts(accounts)
      setContract(contract)
      
      await loadNFtsData();

    };

    init();
  }, []);

  const loadNFtsData = async () => {
    setLoading(true)
    fetch('https://api.covalenthq.com/v1/56/address/' + owner + '/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=true&key=' + apiKey)
    .then(response => response.json())
    .then(result => {
      console.log('result',result)
      var no_nft = result.data.items.filter(item => item.type != "nft");
      var marker_data = no_nft.map(item => ({ 
        "asset": "<img src='" + item.logo_url + "' width='28px' />" + item.contract_name,
        "symbol": item.contract_ticker_symbol,
        "contract_address": item.contract_address,
        "quantity": item.quote_rate ? item.quote / item.quote_rate : 0,
        "price": item.quote_rate,
        "value": item.quote,
      }));

      if (marker_data.length)
        setNoNfts(marker_data)

      var nft = result.data.items.filter(item => item.type == "nft");
      var nft_data = new Array();
      nft.forEach(item => { 
        if (item.nft_data) {
          item.nft_data.forEach(nft_item => {
            nft_data.push({
            "token": item.contract_name,           
            "type": item.supports_erc,           
            "token_id": nft_item.token_id,              
            });
          });
        }
      });
      if (nft_data.length)
        setNfts(nft_data)
    });

    setLoading(false)
  }
  
  return (
    <div className="main_container">
      <Sidebar />
      <TopNav />
      <div className="right_col" role="main">
        <div style={{width: '100%'}}>
          <div className="row">
            <h3 className="text-center"><a href="https://www.vestallytoken.com/">Click Here to Go Home</a></h3>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <h1 className="text-center">VTT Holding Account Dashboard</h1>
              <hr/>
              <br/>
              <div className="col-lg-12 text-center">
                <label>VTT Project ADDRESS : </label>
                <input type="text" name="address" id="address" value={owner} style={{width: '400px'}} readonly/>
                <button type="button" name="submit" id="submit" style={{display: 'none'}}>Submit</button>
              </div>
              <div id="content-nonft" className="col-lg-12">
                <h2 className="text-center font-24 mt-20">Tokens</h2>
                <table id="data-table" className="no_nft_table">
                  <thead>
                    <tr>
                        <th>Asset</th>
                        <th>Symbol</th>
                        <th>Contract Address</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Value</th>
                    </tr>
                  </thead>
                  {
                    loading ? "": (
                      <tbody>
                        {
                          noNfts && noNfts.length > 0 ? (
                            noNfts.map((item, key) => {
                              return (
                              <tr key={key}>
                                <td><div dangerouslySetInnerHTML={{__html: item.asset }}></div></td>
                                <td>{item.symbol}</td>
                                <td>{item.contract_address}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                                <td>{item.value}</td>
                              </tr>
                              )
                            
                            })
                          ) : (
                            <h3 className="text-center font-20 w-100">No NFT found</h3>
                          )
                        
                        }
                      </tbody>
                    )
                  }
                </table>
              </div>
              <div id="content-nft" className="col-lg-12">
                <h2 className="text-center font-24 mt-20">NFT</h2>
                <table id="data-table-nft" className="nft_table">
                  <thead>
                    <tr>
                        <th>Token</th>
                        <th>Token ID</th>
                        <th>Type</th>
                    </tr>
                  </thead>
                  {
                    loading ? "": (
                      <tbody>
                        {
                          nfts && nfts.length > 0 ? (
                            nfts.map((item, key) => {
                              return (
                              <tr key={key}>
                                <td>{item.token}</td>
                                <td>{item.token_id}</td>
                                <td>{item.type}</td>
                              </tr>
                              )
                            
                            })
                          ) : (
                            <h3 className="text-center font-20 w-100">No NFT found</h3>
                          )
                        }
                      </tbody>
                    )
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account