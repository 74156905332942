import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar'
import TopNavV2 from '../components/TopNavV2'
import getWeb3 from "../config/getWeb3"
import { ELECTION_ABI, ELECTION_CONTRACT_ADDRESS } from '../config'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import commaNumber from 'comma-number'

function Proposals(props) {
  const { balance, setAccount, setBalance, account } = props;
  const [accounts, setAccounts] = useState({});
  const [contract, setContract] = useState({});
  const [proposals, setProposals] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasVoted, setHasVoted] = useState(false)
  const [selectedProposal, setSelectedProposal] = useState("")
  const [metamaskError, setMetamaskError] = useState("")

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const web3 = await getWeb3();
      if(web3 !== null) {
        setMetamaskError("")
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        // const network = Election.networks[networkId];
        const contract = new web3.eth.Contract(
          ELECTION_ABI,
          ELECTION_CONTRACT_ADDRESS
        );
        

        setAccounts(accounts)
        setContract(contract)
        
        await loadBlockchainData(accounts, contract);
      } else {
        setMetamaskError("Please Install Metamask Or Use a Compatible Browser")
      }

      setLoading(false);
    };

    init();
  }, [metamaskError]);

  const loadBlockchainData = async (accounts, contract) => {
    const count = await contract.methods.proposalsCount().call();
      let proposalsTemp = []
      for( let i = 1; i <= count; i++ ) {
        contract.methods.proposals(i).call().then(res => {
          proposalsTemp.push({id: res.id, name: res.name, voteCount: commaNumber(res.voteCount / (10 ** 9))})
            setProposals([...proposalsTemp]);
        })
      }

      const hasVoted = await contract.methods.voters(accounts[0]).call();
      setHasVoted(hasVoted)
  }

  const selectProposalHandler = async (e) => {
    e.preventDefault();
    if(selectedProposal === "") {
      alert("Please select Proposal")
      return;
    }

    if(hasVoted) {
      alert("You have already vote proposal")
      return;
    }

    await contract.methods.vote(selectedProposal).send({from: accounts[0]}).then(res => {
      loadBlockchainData(contract, accounts[0]);
      setHasVoted(true);
    })
  }

  return (
    <div className="container-fluid">
        <div className="row flex-nowrap">
            <div className="col-2 col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
                <Sidebar />
            </div>
            <div className="col py-3">
              <TopNavV2 
                balance={balance}
                account={account}
                setAccount={setAccount}
                setBalance={setBalance}
                >
              </TopNavV2>
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-success" role="alert">
                    <strong>Update!</strong> "DAPP Proposals Live : Place Votes before 3/4/2022"
                  </div>

                  <h4 className="mt-5 mb-3 text-center">Proposals</h4>

                  <div className="row">
                    <div className="m-auto col-md-6">
                      {
                        metamaskError ? (
                          <div className="alert alert-success" role="alert">
                            {metamaskError}
                          </div>
                        ): (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Votes</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              proposals.length && proposals.sort((a, b) => a.id > b.id ? 1 : -1).map((item,key) => {
                                return (
                                  <tr key={key}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.voteCount}</td>
                                  </tr>
                                )
                              })
                            }
                            {
                              proposals.length === 0 && (
                              <tr>
                                <td colSpan="4">
                                  <h5 className="text-center">No Proposal Listed...</h5>
                                </td>
                              </tr>
                              )
                            }
                            </tbody>
                          </Table>
                        )
                      }
                    </div>
                  </div>

                </div>

                <div className="col-md-12">
                  <div className="row mt-5">
                    <div className="m-auto col-md-6 d-flex justify-content-between">
                      <Form.Select size="md" className="" onChange={(e) => setSelectedProposal(e.target.value)}>
                        <option value="">Please select proposal to vote:</option>
                        {
                          proposals.length && proposals.sort((a, b) => a.id > b.id ? 1 : -1).map((item, key) => {
                            return (
                              <option value={item.id} key={key}>{item.name}</option>
                            )
                          })
                        }
                      </Form.Select>
                      <Button
                        className="font-12"
                        variant="primary"
                        disabled={hasVoted}
                        onClick={(e) => selectProposalHandler(e)}
                      >
                        {hasVoted ? 'Already Voted' : 'Vote'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Proposals
