import { useEffect, useState } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import Web3 from "web3";

// pages
import DashboardV2 from './pages/Dashboardv2';
import Account from './pages/Account';
import Proposals from './pages/Proposals';

const getAccount = () => {
  const account = localStorage.getItem("account");
  if (account) {
    return localStorage.getItem("account");
  } else {
    return false;
  }
};

function App() {
  
  const web3 = new Web3(Web3.givenProvider)
  const [account, setAccount] = useState(getAccount());
  const [balance, setBalance] = useState();

  const connectWalletOnLoad = async () => {
    const balance = await web3.eth.getBalance(account);
    const decimal = 9;
    setBalance(balance / ( 10 ** decimal));
  };

  useEffect(() => {
    if (account) {
      connectWalletOnLoad();
      localStorage.setItem("account",account)
    }
  }, [account]);

  return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/dashboard" />} />
        {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
        <Route exact path="/dashboard" element=
          {
            <DashboardV2 
              balance={balance}
              account={account}
              setAccount={setAccount}
              setBalance={setBalance} 
            />
          } 
        />
        <Route exact path="/account" element=
          {
            <Account 
              balance={balance}
              account={account}
              setAccount={setAccount}
              setBalance={setBalance} 
            />
          } />
        <Route exact path="/proposals" element=
          {
            <Proposals
              balance={balance}
              account={account}
              setAccount={setAccount}
              setBalance={setBalance}
            />
          } />
      </Routes>
  );
}

export default App;
