import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import TopNavV2 from '../components/TopNavV2'
import getWeb3 from '../config/getWeb3'
import { VTT_ABI, VTT_CONTRACT_ADDRESS } from '../config'
import { Oval } from  'react-loader-spinner'
import commaNumber from 'comma-number'
// import '../assets/build/css/dashboard.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkMetaMaskEnable } from '../utilities/Web3/account'
import Web3 from 'web3'


function DashboardV2(props) {
  
  const { balance, setAccount, setBalance, account } = props;
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingData, setStatsData] = useState(true)
  const [network, setNetwork] = useState();
  const [isMetamaskLogin, setIsMetamaskLogin] = useState(null);
  const [isBscNet, setIsBscNet] = useState(null);
  const [isMetamaskEnable, setIsMetamaskEnable] = useState(null);


  useEffect(() => {

    if (window.ethereum) {
      // use MetaMask's provider
      window.ethereum.enable(); // get permission to access accounts

      // detect Metamask account change
      window.ethereum.on('accountsChanged', function (accounts) {
        setState({currentAccount: accounts[0]})

      });

      // detect Network account change
      window.ethereum.on('networkChanged',  async function(networkId){
        if(networkId !== 56) {
          const validChainId = process.env.REACT_APP_CHAIN;
          try {
            await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x' + validChainId }] });
          }
          catch (err) {
            setIsBscNet("Please connect to Binance network")
          }
        }
        setState({networkId: networkId})
      });
    } 
    checkWeb3();
    getDashboardStats()

  }, [loading, network])

  const checkWeb3 = async () => {
    if(window.ethereum !== undefined || window.web3 !== undefined) {
      setIsMetamaskEnable(null)
      window.ethereum.enable().then(acc => {
        setState({currentAccount: acc[0]})
      });

      const web3 = new Web3(window.ethereum);
      const acc = await web3.eth.getAccounts()

      if(acc.length === 0) {
        setIsMetamaskLogin("Please login to your metamask and refresh page")
        // window.location.reload();
      } else {
    
        setIsMetamaskLogin(null)
        const validChainId = process.env.REACT_APP_CHAIN;
        const network = await web3.eth.net.getId();

        if(network !== 56) {
            setIsBscNet("Please connect to Binance network")
          try {
            await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x' + validChainId }] });
          }
          catch (err) {
          } 
        }
        else {
          setIsBscNet(null)
        }

      }
    } else {
      setIsMetamaskEnable("Please Install Metamask Or Use a Compatible Browser")
    }
  }

  const getDashboardStats = async () => {
    setStatsData(true)
    if((window.ethereum !== undefined || window.web3 !== undefined) ) {
      const web3 = new Web3(window.ethereum);
      const acc = await web3.eth.getAccounts()
      const contract = new web3.eth.Contract(VTT_ABI, VTT_CONTRACT_ADDRESS, {
        from: acc[0],
      });

      setState({contract})

      const owner = await contract.methods.owner().call()
      const name = await contract.methods.name().call()
      const symbol = await contract.methods.symbol().call()
      const decimals = await contract.methods.decimals().call()
      const totalReflection = commaNumber((await contract.methods.totalHODLrRewards().call()) / (10 ** 9))
      const walletBalance = commaNumber((await contract.methods.balanceOf(acc[0]).call()) / (10 ** 9))
      const charityAddress = await contract.methods._charityAddress().call()
      const charityAddressHoldings = commaNumber((await contract.methods.balanceOf(charityAddress).call()) / ( 10 ** 9))
      const marketingAddress = await contract.methods._marketingAddress().call()
      const marketingAddressHoldings = commaNumber((await contract.methods.balanceOf(marketingAddress).call())/ ( 10 ** 9))
      const totalSupplyTemp = await contract.methods.totalSupply().call()
      const totalBurnedTemp = await contract.methods.totalBurned().call()
      const totalSupply = commaNumber(totalSupplyTemp)
      const totalBurned = commaNumber(totalBurnedTemp / ( 10 ** 9))
      // convert suplly into trillion
      let circulatingSupply = (totalSupplyTemp - totalBurnedTemp)
      circulatingSupply = circulatingSupply.toString();
      circulatingSupply = (circulatingSupply.slice(0, (circulatingSupply.indexOf("."))+3)); //With 3 exposing the hundredths place
      circulatingSupply = Math.round(circulatingSupply * 100);

      setState({owner, name, symbol, decimals, totalSupply, totalBurned, circulatingSupply, charityAddressHoldings, marketingAddressHoldings, walletBalance, totalReflection})
      setStatsData(false) 
    } else {
      checkWeb3();
    }
  }
  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row flex-nowrap">
            <div className="col-2 col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
              <Sidebar />
            </div>
          <div className="col py-3">
            <TopNavV2 
             balance={balance}
             account={account}
             setAccount={setAccount}
             setBalance={setBalance}
            ></TopNavV2>
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-success" role="alert">
                  <strong>Update!</strong> "DAPP Proposals Live : Place Votes before 3/4/2022"
                </div>

                {
                  isMetamaskLogin ? (
                    <div className="alert alert-success" role="alert">
                      {isMetamaskLogin}
                    </div>
                  ): ""
                }

                {
                  isBscNet ? (
                    <div className="alert alert-success" role="alert">
                      {isBscNet}
                    </div>
                  ) : ""
                }

                {
                  isMetamaskEnable ? (
                    <div className="alert alert-success" role="alert">
                      {isMetamaskEnable}
                    </div>
                  ) : ""
                }

                {
                  isMetamaskLogin === null && isMetamaskEnable === null ? (
                    <div className="row">
                      <div className="col-md-3">
                        <div className="d-card-warning">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                            <h2 className="d-stats-heading">{state.name}</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Token Name</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-card-info">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.symbol}</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Token Symbol</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-card-success">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">1 Quadrillion</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Original Mint Supply</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-card-danger">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.totalBurned}</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Token Burned</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-card-primary">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">~{state.circulatingSupply} Trillion</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">SUPPLY - BURN</span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="d-card-primary1">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.charityAddressHoldings}</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Charity Address Holdings</span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="d-card-primary2">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.marketingAddressHoldings}</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Marketing Address Holdings</span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="d-card-primary3">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.walletBalance} VTT</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Wallet Balance</span>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="d-card-primary3">
                          {
                          loadingData ? (
                            <Oval color="#FFF" height={20} width={20} />
                          ) : (
                            <>
                              <h2 className="d-stats-heading">{state.totalReflection} VTT</h2>
                            </>
                          ) 
                          }
                          <span className="d-stats-text">Total Reflection</span>
                        </div>
                      </div>
                      
                    </div>
                  ): ""
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardV2
