import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import metaMask from "../assets/icons/MetaMaskFox.svg";
import coinBase from "../assets/icons/coinbase.svg";
import walletConnects from "../assets/icons/walletconnect.png";
import trustWallet from "../assets/icons/trustwallet.png";
import closeIcon from "../assets/icons/closeicon.svg";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import WalletConnectProvider from "@walletconnect/web3-provider";
import getWeb3 from '../config/getWeb3';
import browser from 'browser-detect';
 


const ConnectWalletModal = (props) => {
  // const url = "https://mainnet.infura.io/v3/53e9da5cab374c38ad516647df016ec7";
  const url = "https://bsc-dataseed.binance.org/"
  const { setAccount, setBalance, show, onHide } = props;
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545", url);
  // const web3 = await getWeb3();
  
  const { activate, account, library, chainId } = useWeb3React();
const browserInfo = browser();

  const connectMetaMaskWalletOnClick = async () => {
    console.log('window.ethereum',window.ethereum)
    console.log('window.web3',window.web3)
    if (window.ethereum !== undefined) {
      const address = await window.ethereum.enable();
      localStorage.setItem("account", address);
      setAccount(address[0]);
      if (address.length > 0) {
        const balance = await web3.eth.getBalance(address[0]);
        const ethBalance = web3.utils.fromWei(balance, "ether");
        setBalance(ethBalance);
        props.onHide();
      }
    } else {
      try {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const connectToCoinBase = async () => {
    const walletlink = new WalletLinkConnector({
      url: url,
      appName: "vesTally",
      supportedChainIds: [56],
    });
    try {
      await activate(walletlink);
    } catch (ex) {
      console.log(ex);
    }
  };

  const connectToWalletConnect = async () => {
    const walletconnect = new WalletConnectProvider({
      rpc: {
        56: url
      },
      network: "binance",
      qrcode: true,
      qrcodeModalOptions: {
        mobileLinks: [
          "metamask",
          "trust",
        ]
    }
    });
    // const walletconnect = new WalletConnectConnector({
    //   rpc: {
    //     56: url,
    //   },
    //   qrcode: true,
    //   pollingInterval: 15000,
    // });
    try {
      await walletconnect.enable();
      // await activate(walletconnect);
    } catch (ex) {
      // console.log(ex);
    }
  };

  const getBalance = async (account) => {
    const balance = await web3.eth.getBalance(account);
    const ethBalance = web3.utils.fromWei(balance, "ether");
    setBalance(ethBalance);
    if (balance) {
      props.onHide();
    }
  };

  useEffect(() => {
    if (!!account && !!library) {
      localStorage.setItem("account", account);
      getBalance(account);
    }
  }, [account, library, chainId]);

  return (
    <>
      <Modal
        className="Connect-Modal"
        size="lg"
        show={show}
        onHide={onHide}
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <span className="closeButton" onClick={props.onHide}>
          <img src={closeIcon} alt="" />
        </span>

        <Modal.Body>
          <h4>Connect your wallet</h4>
          <div className="button-section">
            {
              browserInfo.name !== 'safari' && (
                <button
                  className="button"
                  onClick={() => connectMetaMaskWalletOnClick()}
                >
                  Metamask
                  <span>
                    <img src={metaMask} alt="" />
                  </span>
                </button>
              )
            }
            <button className="button" onClick={() => connectToCoinBase()}>
              Coinbase
              <span>
                <img src={coinBase} alt="" />
              </span>
            </button>
            <button className="button" onClick={() => connectToWalletConnect()}>
              WalletConnect
              <span>
                <img src={walletConnects} alt="" />
              </span>
            </button>
            <button className="button" onClick={() => connectToWalletConnect()}>
              Trust Wallet
              <span>
                <img src={trustWallet} alt="" />
              </span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
