import { useEffect, useState } from 'react'
import ConnectWalletModal from "./connectWalletModal";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const TopNavV2 = (props) => {
  const { balance, account, setAccount, setBalance } = props;
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  // useEffect(() => {
  //   const checkNetwork = async () => {
  //     const validChainId = process.env.REACT_APP_CHAIN;
  //     try {
  //       await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x' + validChainId }] });
  //     }
  //     catch (err) {
  //       toast.error('Please connect to Binance network.', {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  //   checkNetwork();
  // }, [])
  
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="top-header">
        <div className="">
          {balance !== undefined ? (
            <p className="account-text">
              <b>Account:</b> {account}
            </p>
            ): ""
          }
        </div>
        <div className="">
          {balance !== undefined ? (
          <button type="button" className="btn-connect" onClick={() => {
                setBalance(undefined);
                localStorage.removeItem('account');
              }}>Disconnect Wallet</button>
            
          ) : (
            <button type="button" className="btn-connect" onClick={() => setShowConnectWallet(true)}>Connect Wallet</button>
          )
          }
        </div>

      </div>

      <div>
        {showConnectWallet && (
          <ConnectWalletModal
            show={showConnectWallet}
            onHide={() => setShowConnectWallet(false)}
            setAccount={setAccount}
            setBalance={setBalance}
          />
        )}
      </div>
    </>
  )
}

export default TopNavV2