import React from 'react'
import logo from '../assets/images/VTTLogo.png'
import {
    Link,
} from 'react-router-dom';
const Sidebar = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <img src={logo} alt="logo" className="imgThumbnail" /> 
              <span className="fs-5 d-none d-sm-inline">VesTally Token</span>
          </a>
          <ul className="nav nav-pills mt-4 flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start text-white" id="menu">
              <li className="nav-item">
                  <a href="https://vestallytoken.com/" target="blank" className="nav-link text-white p-0 pb-3">
                      <i className="fs-6 bi-house-fill"></i> <span className="ms-1 d-none d-sm-inline">Home</span>
                  </a>
              </li>
              <li>
                  <Link className="nav-link text-white p-0 pb-3" to="/dashboard">
                      <i className="fs-6 bi-speedometer"></i> <span className="ms-1 d-none d-sm-inline">Dashboard</span>
                  </Link>
              </li>
              <li>
                  <Link className="nav-link text-white p-0 pb-3" to="/proposals">
                      <i className="fs-6 bi-cursor-fill"></i> <span className="ms-1 d-none d-sm-inline">Proposals</span>
                  </Link>
              </li>
          </ul>
      </div>
    </>
  );
}

export default Sidebar